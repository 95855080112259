var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"`modal-card-head`"},[_c('div',{staticClass:"container"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('header',{class:`modal-card-head`},[_c('p',{staticClass:"modal-card-title has-text-white"},[_vm._v(_vm._s(_vm.$t('users.Users')))])]),_c('section',{staticClass:"modal-card-body has-text-white",staticStyle:{"width":"700px"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required|numeric|minmax:9,10","name":"Numero de cedula"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Cedula","type":{
                    'is-danger': errors[0],
                    'is-success': valid,
                  },"message":errors}},[_c('b-input',{model:{value:(_vm.user.identity_card),callback:function ($$v) {_vm.$set(_vm.user, "identity_card", $$v)},expression:"user.identity_card"}})],1)]}}],null,true)}),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required","name":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Nombre","type":{
                    'is-danger': errors[0],
                    'is-success': valid,
                  },"message":errors}},[_c('b-input',{model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})],1)]}}],null,true)}),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required","name":"Apellido"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Primer apellido","type":{
                    'is-danger': errors[0],
                    'is-success': valid,
                  },"message":errors}},[_c('b-input',{model:{value:(_vm.user.middle_name),callback:function ($$v) {_vm.$set(_vm.user, "middle_name", $$v)},expression:"user.middle_name"}})],1)]}}],null,true)}),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required","name":"Apellido"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Segundo apellido","type":{
                    'is-danger': errors[0],
                    'is-success': valid,
                  },"message":errors}},[_c('b-input',{model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1)]}}],null,true)}),_c('br')],1),_c('br'),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Usuario"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Usuario","type":{
                    'is-danger': errors[0],
                    'is-success': valid,
                  },"message":errors}},[_c('b-input',{attrs:{"maxlength":"16"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1)]}}],null,true)}),_c('br'),(_vm.showPass)?_c('ValidationProvider',{attrs:{"rules":"required","name":"Contraseña"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{attrs:{"label":"Contraseña","type":{
                    'is-danger': errors[0],
                    'is-success': valid,
                  },"message":errors}},[_c('b-input',{attrs:{"maxlength":"16","icon":"lock","type":"password","password-reveal":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1)]}}],null,true)}):_vm._e(),_c('br'),_c('b-field',{attrs:{"label":"Rol del usuario"}},[_c('b-select',{attrs:{"loading":_vm.loading,"expanded":"","placeholder":"Seleccione el rol"},model:{value:(_vm.usergroups),callback:function ($$v) {_vm.usergroups=$$v},expression:"usergroups"}},_vm._l((_vm.groups),function(group){return _c('option',{key:group.value,domProps:{"value":group.value}},[_vm._v(" "+_vm._s(_vm.$t(`${group.text}`))+" ")])}),0)],1)],1)])]),_c('footer',{staticClass:"modal-card-foot"},[_c('div',{staticClass:"level",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"level-left is-align-items-start"}),_c('div',{staticClass:"level is-justify-content-space-between",staticStyle:{"height":"40px"}},[_c('button',{staticClass:"button co-status-blue m-6",attrs:{"type":"button"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('div',{},[_c('b-button',{class:`is-secondary has-text-white is-primary`,attrs:{"loading":_vm.isLoading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v("Guardar")])],1)])])])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }